<template>
  <div
    class="contact-information"
  >
    <Phone
        v-model="form.phone"
        :error="formErrors.phone"
        :disabled="!!user.contactData.id"
        @focus="phoneFocus"
      title="Номер телефона"
    />
    <Field
        v-model="form.email"
        :error="formErrors.email"
        @focus="emailFocus"
      title="E-mail"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import './contact-information.scss'
import Phone from '@index/components/common/field/Phone'
import Field from '@index/components/common/field/Field'
import validation from '@index/mixins/validation'
import Validation from '@index/ext/validation/Validation'
import stat from '@/api/stat'

export default {
  name: 'ContactInformation',
    computed: {
      ...mapGetters({
          user: 'application/user',
      })
    },
    methods: {
        phoneFocus() {
            if (this.phoneFocused)
                return

            this.phoneFocused = true;
            stat('phone')
        },
        emailFocus() {
            if (this.emailFocused)
                return

            this.emailFocused = true;
            stat('email')
        }
    },
    data() {
      return {
          emailFocused: false,
          phoneFocused: false,
        form: {
            phone: '',
            email: ''
        },
          formRules: {
            phone: [Validation.REQUIRED, Validation.PHONE, Validation.MOBILE],
            email: [Validation.EMAIL]
          }
      }
    },
    created() {
      const {
          phone,
          email
      } = this.user.contactData

        this.form.phone = phone
        this.form.email = email

        if (this.form.phone || this.user.contactData.id)
            return

        this.form.phone = this.$route.query?.phone || '';
    },
  mixins: [validation],
  components: {
    Phone,
    Field,
  },
};
</script>
