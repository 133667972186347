<template>
    <div
        class="radio"
        :class="classes"
    >
        <p v-if="title"
           class="radio__title">
            {{ title }}:
        </p>
        <ul class="radio__list">
            <li v-for="item in list"
                class="radio__list-element"
                :key="item.key">
                <label class="radio__label">
                    <span class="radio__input">
                        <input v-model="popupModel"
                               :value="item.key"
                               type="radio"/>
                        <span></span>
                    </span>
                    {{ item.value }}
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Radio',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        list: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            default: ''
        },
        model: {
            required: true
        },
        error: {
            type: String,
            default: ''
        }
    },
    computed: {
        popupModel: {
            get() {
                return this.model
            },
            set(v) {
                this.$emit('change', v)
            }
        },
        classes: {
            get() {
                return {
                    'radio_error': this.error
                }
            }
        }
    },
}
</script>

<style lang="scss">
@import "radio";
</style>
